<template>

    <v-container fluid>

        <v-row class="fill-height" align="center" justify="center">

            <div class='impressum'>
            
        
                <p class="display-1 primary--text">Impressum</p>

                <p class='title mt-3'>Herausgeber des Internetauftritts</p>

                <p>
                    Georg-August-Universität Göttingen<br> 
                    Stiftung öffentlichen Rechts<br> 
                    Universitätsmedizin Göttingen<br> 
                    Robert-Koch-Str. 40<br> 
                    37075 Göttingen<br> 
                    <br> 
                    vertreten durch den Vorstand<br> 
                    <br> 
                    Telefon: +49 (0)551 39-0<br> 
                    E-Mail: <a href="mailto:poststelle@med.uni-goettingen.de">poststelle@med.uni-goettingen.de</a><br> 
                    <br> 
                    (im Folgenden „Universitätsmedizin Göttingen“)
                </p>

                <p class="title mt-3">Aufsichtsbehörde</p>

                <p><a href="https://www.umg.eu/ueber-uns/stiftung/stiftungsausschuss/">Stiftungsausschuss der Universitätsmedizin Göttingen</a></p>

                <p class="title mt-3">Umsatzsteuer-Identifikationsnummer</p>

                <p>DE 286005408</p>
                    
                <p class="title mt-3">Inhaltlich verantwortlich für das UMG-Dachportal gemäß §5 Abs. 2 TMG<p>
                <p>
                    <a href="https://www.umg.eu/ueber-uns/vorstand/">Vorstand der Universitätsmedizin Göttingen</a><br>
                    <br>
                    Vorstand Forschung und Lehre: Prof. Dr. Wolfgang Brück<br>
                    Vorstand Krankenversorgung: Dr. Martin Siess<br>
                    Ständ. Vertreter Vorstand Wirtschaftsführung und Administration: Jens Finke
                </p>

                <p class="title mt-3">Zuständige Ärztekammer</p>
                <p>
                    <a href="https://www.aekn.de/">Landesärztekammer Niedersachsen (Berufsordnung)</a><br>
                    Berliner Allee 20<br>
                    30175 Hannover
                </p>

                <p class="title mt-3">Zuständige Psychotherapeutenkammer</p>
                <p>
                    <a href="https://www.pknds.de/">Psychotherapeutenkammer Niedersachsen</a><br>
                    Leisewitzstr. 47<br>
                    30175 Hannover
                </p>

                <p class="title mt-3">Zuständige Zahnärztekammer</p>
                <p>
                    <a href="https://zkn.de/">Zahnärztekammer Niedersachsen (Berufsordnung)</a><br>
                    Zeißstraße 11a<br>
                    30519 Hannover

                </p>

                <p class="title mt-3">Zuständige Apothekenkammer</p>
                <p>
                    <a href="https://www.apothekerkammer-niedersachsen.de/">Apothekerkammer Niedersachsen (Berufsordnung)</a><br>
                    An der Markuskirche 4<br>
                    30163 Hannover<br>
                </p>

                <p class="title mt-3">Geltungsbereich des Impressums</p>

                <p>
                    Dieses Impressum gilt für die Web-Auftritte des Forschungsprojektes @myTabu, die durch die URL <a href="https://www.mytabu.umg.eu">https://www.mytabu.umg.eu</a> ansteuerbar sind.<br>
                    <br>
                    <b>Inhaltlich verantwortlich:</b><br>
                    Prof. Dr. Jürgen L. Müller und Dr. Peter Fromberger<br>
                    Klinik für Psychiatrie und Psychotherapie – Forensische Psychiatrie<br>
                    Universitätsmedizin Göttingen<br>
                    Rosdorfer Weg 70<br>
                    37081 Göttingen, Germany<br>
                    Tel: +49 551 4022108

                </p>
                
                <p class="title mt-3">Haftungsausschluss für den Inhalt des Onlineangebotes</p>

                <p>
                    Die Inhalte dieser Website werden mit größtmöglicher Sorgfalt erstellt. Die Universitätsmedizin Göttingen übernimmt jedoch keine Gewähr für die Aktualität, Richtigkeit, Vollständigkeit und Qualität der bereitgestellten Informationen.
                    Für Schäden materieller oder immaterieller Art, die durch die Nutzung oder die Nichtnutzung der Inhalte oder durch die Nutzung oder Nichtnutzung fehlerhafter und unvollständiger Informationen unmittelbar oder mittelbar verursacht werden, 
                    haftet die Universitätsmedizin Göttingen nicht. Die Benutzung erfolgt auf eigene Gefahr.
                </p>

                <p>
                   Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten 
                   sind ausschließlich deren Betreiber verantwortlich. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist ohne konkrete Anhaltspunkte 
                   einer rechtlichen Verletzung nicht zumutbar, bei entsprechendem Bekanntwerden von Rechtsverletzungen werden derartige Links umgehend entfernt. 
                   Die Universitätsmedizin Göttingen behält sich darüber hinaus vor, Teile der Seiten oder das gesamte Angebot ohne besondere Ankündigung zu 
                   verändern, ergänzen oder zu löschen. 
                </p>

                <p class="title mt-3">Urheber- und Kennzeichenrecht</p>

                <p>
                    Das Layout der Homepage, die verwendeten Grafiken sowie die sonstigen Inhalte der Internetpräsenz der Universitätsmedizin Göttingen 
                    unterliegen dem deutschen Urheber- und Kennzeichenrecht. Die Universitätsmedizin Göttingen ist stets bestrebt, die Urheberrechte anderer 
                    zu beachten bzw. auf selbst erstellte sowie lizenzfreie Werke zurückzugreifen. Jede vom deutschen Urheber- und Kennzeichenrecht nicht 
                    zugelassene Verwertung bedarf der vorherigen schriftlichen Zustimmung der Universitätsmedizin Göttingen oder des jeweiligen Rechteinhabers. 
                    Dies gilt insbesondere für Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in 
                    Datenbanken oder anderen elektronischen Medien und Systemen. Inhalte und Rechte Dritter sind dabei als solche gekennzeichnet. Die unerlaubte 
                    Vervielfältigung oder Weitergabe einzelner Inhalte oder kompletter Seiten ist nicht gestattet und strafbar. Lediglich die Herstellung von 
                    Kopien und Downloads von Internet-Seiten für den privaten, wissenschaftlichen und nicht kommerziellen Gebrauch ist erlaubt. 
                    Die Darstellung dieser Website in fremden Frames ist nur mit schriftlicher Erlaubnis zulässig.
                </p>

                <p class="title mt-3">Namensnennung und Benutzung der Marke „UMG“</p>

                <p>
                    Der Name „UMG“ ist als europäische Marke eingetragen. Eine Verwendung zu kommerziellen Zwecken (insbes. im Rahmen von Werbung) ist nur 
                    nach vorheriger schriftlicher Erlaubnis durch die Universitätsmedizin Göttingen als Markenrechtsinhaberin erlaubt.
                </p>


            </div>

        </v-row>

    </v-container>
</template>

<script>
export default {
    name: 'impressum',

    data: () => ({
        //
    }),

    created() {

    },
}
</script>

<style scoped>

</style>